import React, { useRef, useState } from 'react'
import Slick from 'react-slick'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'

import { HeroProps } from './types'
import { functions, path, useApp } from '@wap-client/core'
import Button from '@/components/base/button/Button'
import Video from '@/components/base/video/Video'
import Anchor from '@/components/base/anchor/Anchor'

const Hero: React.FC<HeroProps> = ({ data }) => {
  const app = useApp()
  const slider = useRef<Slick>(null)
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
  const [activeSlider, setActiveSlider] = useState(0)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  }

  const videoSettings = {
    autoPlay: true,
    controls: false,
    loop: true,
    muted: true,
    playsInline: true,
    preload: 'none',
  }

  const goToSlide = (index: number) => {
    if (slider.current) {
      slider.current.slickGoTo(index)
    }
  }

  const handleSlideBeforeChange = (prevIndex: number, nextIndex: number) => {
    if (videoRefs && videoRefs.current && videoRefs.current.length) {
      const currentVideo = videoRefs.current[nextIndex]
      const prevVideo = videoRefs.current[prevIndex]

      if (prevVideo) {
        prevVideo.currentTime = 0
        prevVideo.pause()
      }

      if (currentVideo) {
        if (currentVideo.readyState > 1) {
          currentVideo.play()
        }
      }
    }

    setActiveSlider(nextIndex)
  }

  const handleSlideAfterChange = (current: number) => {
    setActiveSlider(current)
  }

  return (
    <div className="hero">
      <Slick
        ref={slider}
        {...settings}
        beforeChange={handleSlideBeforeChange}
        afterChange={handleSlideAfterChange}
      >
        {data?.map((item, index) => (
          <div key={index}>
            <div className="hero__item">
              <Anchor {...item.link}>
                <figure>
                  {item.video.src ? (
                    <Video
                      {...item.video}
                      {...videoSettings}
                      ref={(node) => (videoRefs.current[index] = node)}
                    />
                  ) : (
                    <Image {...item.image} alt={item.title} />
                  )}
                </figure>
                <div className="hero__item__detail">
                  <Container size="extended">
                    <Row>
                      <Column xs={{ size: 12 }} md={{ size: 7 }}>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.description }}
                          className="desc"
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column xs={{ size: 12 }}>
                        <div className="title">{item.title}</div>
                      </Column>
                    </Row>
                  </Container>
                </div>
              </Anchor>
            </div>
          </div>
        ))}
      </Slick>
      <div className="hero__dots">
        {data?.map((item, index) => (
          <div
            className={functions.classnames(
              'hero__dot',
              index === activeSlider && 'hero__dot--active'
            )}
            key={index}
          >
            <Button onClick={() => goToSlide(index)}></Button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Hero
